<template>
	<div id="app" class="h-100" :class="[skinClasses]">
		<component :is="layout">
			<router-view />
		</component>

		<scroll-to-top v-if="enableScrollToTop && !isViewPurchase" />

		<b-modal
			ref="sessionWarningModal"
			id="sessionWarningModal"
			title="Advertencia"
			hide-header-close
			no-close-on-backdrop
			no-close-on-esc
			centered
			@hide="onModalHide"
			size="sm"
		>
			<div>
				Tu sesión se cerrará en <strong>{{ countdown }}</strong> segundos por inactividad.
			</div>
			<template #modal-footer>
				<b-button size="sm" variant="danger" @click="logout">Cerrar sesión</b-button>
				<b-button size="sm" variant="primary" @click="resetIdleTimer">Mantener sesión activa</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { BModal, BButton, } from 'bootstrap-vue'
import { HubConnectionBuilder } from "@microsoft/signalr";
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

import AuthProvider from '@/providers/Auth'
const AuthResource = new AuthProvider()

export default {
	components: {
		BModal,
		BButton,

		// Layouts
		LayoutHorizontal,
		LayoutVertical,
		LayoutFull,

		ScrollToTop,
	},
	data() {
		return {
			hubConnection: null,
			messages: [],
			idleTimeout: null,
			// idleTimeLimit: 8000, // 10 minutos
			countdown: 30, // Tiempo inicial de 30 segundos
			countdownInterval: null, // Para almacenar el interval del countdown
			warningTimeout: null,  // Para mostrar advertencia antes de cerrar sesión
			forceLogoutTimeout : null, // Para cerrar sesión automáticamente
			modalInstance: null, // Instancia del modal de advertencia
		}
	},
	// ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
	// Currently, router.currentRoute is not reactive and doesn't trigger any change
	computed: {
		layout() {
			if (this.$route.meta.layout === 'full') return 'layout-full'
			return `layout-${this.contentLayoutType}`
		},
		contentLayoutType() {
			return this.$store.state.appConfig.layout.type
		},
		isViewPurchase() {
			return store.getters['auth/getUser'] ? store.getters['auth/getCurrentRoute'] === 'apps-purchase' : false
		},
		isLogisticRol() {
			return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getIsLogisticRol'] : false
		},
		isAuxiliarRol() {
			return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getAuxiliarRol'] : false
		},
		isAuthenticate() {
			return store.getters['auth/getIsAuthenticated'] || false
		},
		idleTimeLimit() {
			//return store.getters['auth/getSetting'] ? store.getters['auth/getSetting'].tiempoInactividad : 0
			const minutes = this.findSetting('CIERRE_POR_INACTIVIDAD') || 30
			return minutes * 60000
		}
	},
	created() {
		this.createConnection();
	},
	beforeCreate() {
		// Set colors in theme
		const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

		// eslint-disable-next-line no-plusplus
		for (let i = 0, len = colors.length; i < len; i++) {
			$themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
		}

		// Set Theme Breakpoints
		const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

		// eslint-disable-next-line no-plusplus
		for (let i = 0, len = breakpoints.length; i < len; i++) {
			$themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
		}

		// Set RTL
		const { isRTL } = $themeConfig.layout
		document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
	},
	beforeDestroy() {
		// Eliminar los listeners cuando el componente se destruye
		// window.removeEventListener('mousemove', this.resetIdleTimer);
		window.removeEventListener('keydown', this.resetIdleTimer);
		window.removeEventListener('click', this.resetIdleTimer);
		window.removeEventListener('scroll', this.resetIdleTimer);
		clearTimeout(this.forceLogoutTimeout); // Limpiar el temporizador al destruir el componente
    	clearTimeout(this.idleTimeout); // Limpiar el temporizador al destruir el componente
	},
	async mounted() {	

		this.resetIdleTimer(); // Inicia el temporizador de inactividad

		// Escuchar eventos de interacción del usuario
		// window.addEventListener('mousemove', this.resetIdleTimer);
		window.addEventListener('keydown', this.resetIdleTimer);
		window.addEventListener('click', this.resetIdleTimer);
		window.addEventListener('scroll', this.resetIdleTimer);

		if (this.isAuthenticate) {
			await this.getSettings()
		}
	},
	methods: {
		startCountdown() {
			this.countdown = 30; // Reinicia el countdown a 30 segundos
			this.countdownInterval = setInterval(() => {
				this.countdown -= 1; // Reduce el tiempo en 1 segundo
				if (this.countdown <= 0) {
					clearInterval(this.countdownInterval); // Detiene el countdown
					this.logout(); // Cierra la sesión automáticamente
				}
			}, 1000); // Actualiza cada segundo
		},
		// Reinicia el temporizador de inactividad
		resetIdleTimer() {

			// Verifica si el usuario sigue autenticado antes de reiniciar el temporizador
			if (this.isAuthenticate && !this.isAuxiliarRol) {
				// Limpia los temporizadores anteriores
				clearTimeout(this.idleTimeout);
				clearTimeout(this.warningTimeout);
				clearInterval(this.countdownInterval); // Limpia el intervalo del countdown

				this.$refs['sessionWarningModal'].hide()

				// Configura un nuevo temporizador de inactividad
				this.idleTimeout = setTimeout(() => {
					this.triggerIdle();
				}, this.idleTimeLimit); // Después de 10 minutos (60000 milisegundos)
			}
		},
		// Se activa cuando se detecta inactividad
		triggerIdle() {

			if (this.isAuthenticate && !this.isAuxiliarRol) {
				console.log('Inactividad detectada');

				// Opcional: Mostrar una advertencia antes de cerrar sesión
				// this.warningTimeout = setTimeout(() => {
					this.notifyUser();
				// }, 30000); // Mostrar advertencia después de 30 segundos de inactividad
			}
		},
		onModalHide() {
			clearTimeout(this.forceLogoutTimeout); // Limpia el temporizador al cerrar el modal
			clearInterval(this.countdownInterval); // Limpia el intervalo del countdown
		},
		notifyUser() {

			// Muestra el modal personalizado  			
			this.$refs['sessionWarningModal'].show()

			// Inicia el countdown
  			this.startCountdown();

			this.$nextTick(() => {			

				// Forzar cierre de sesión si no hay respuesta después de 30 segundos
				this.forceLogoutTimeout = setTimeout(() => {				
					console.log('Sesión cerrada automáticamente por no responder a la advertencia de inactividad.');

					store.dispatch("auth/UPDATE_IS_INACTIVITY", true);
					
					// El usuario elige cerrar sesión
					this.logout()

				}, 30000); // 30 segundos
			});
			
		},
		logout() {
			this.$refs['sessionWarningModal'].hide()
			store.dispatch("auth/LOGOUT");

			store.commit('auth/SET_TOKEN_CLOUD', null)
			localStorage.removeItem('token_cloud')
			
		},
		async getSettings() {
			const { data } = await AuthResource.getSettingByCompanyId(1)
			if (data.isSuccesful) {
				store.commit('auth/SET_SETTING', data.data)
			}
		},
		async createConnection() {

			store.dispatch('routing/UPDATE_IS_RELOAD_ABIERTA', false)
			store.dispatch('routing/UPDATE_IS_RELOAD_EN_RUTA', false)
			store.dispatch('routing/UPDATE_IS_RELOAD_CERRADA', false)
			store.dispatch('routing/UPDATE_IS_RELOAD_CLIENTE_PASA', false)
			
			this.hubConnection = new HubConnectionBuilder()
				.withUrl(process.env.VUE_APP_API_URL + "/routingHub")
				.withAutomaticReconnect()
				.build();

			try {
				await this.hubConnection.start();
				console.log("Connection started!");

				this.hubConnection.on("ReceiveRouteStatus", (routing, message) => {
					console.log([routing, message]);
					if (routing.estatus == 'ABIERTA') {
						store.commit('routing/SET_IS_RELOAD_ABIERTA', true)
					} else if (routing.estatus == 'EN RUTA') {
						store.commit('routing/SET_IS_RELOAD_EN_RUTA', true)
					} else if (routing.estatus == 'CERRADA') {
						store.commit('routing/SET_IS_RELOAD_CERRADA', true)
					} else if (routing.estatus == 'CLIENTE PASA') {
						store.commit('routing/SET_IS_RELOAD_CLIENTE_PASA', true)
					}
				});
			} catch (err) {
				console.error(err);
			}
		},
		sendMessage(user, message) {
			this.hubConnection.invoke("SendMessage", user, message)
				.catch(err => console.error(err));
		}
	},
	setup() {
		const { skin, skinClasses } = useAppConfig()
		const { enableScrollToTop } = $themeConfig.layout

		// If skin is dark when initialized => Add class to body
		if (skin.value === 'dark') document.body.classList.add('dark-layout')

		// Provide toast for Composition API usage
		// This for those apps/components which uses composition API
		// Demos will still use Options API for ease
		provideToast({
			hideProgressBar: true,
			closeOnClick: false,
			closeButton: false,
			icon: false,
			timeout: 3000,
			transition: 'Vue-Toastification__fade',
		})

		// Set Window Width in store
		store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
		const { width: windowWidth } = useWindowSize()
		watch(windowWidth, val => {
			store.commit('app/UPDATE_WINDOW_WIDTH', val)
		})

		return {
			skinClasses,
			enableScrollToTop,
		}
	},
}
</script>
