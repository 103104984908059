import Vue from 'vue'

// axios
import axios from 'axios'

// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
// axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE, OPTIONS, HEAD';
// axios.defaults.baseURL = https://api.ferreysa.com/api;
const proxyUrl = 'https://prueba.ferreysa.com/cors-proxy.php?url=';
const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

Vue.prototype.$http = axiosIns

export default axiosIns
